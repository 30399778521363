<template>
    <div>
        <v-row>
            <v-col>
                <v-row>
                    <v-card flat>
                        <v-card-subtitle class="pb-0">
                            Location:
                        </v-card-subtitle>
                        <v-card-title class="pt-0">
                            {{ imageGraph.location }}
                        </v-card-title>
                    </v-card>
                </v-row>

                <v-row>
                    <VueApexCharts
                        height="450"
                        style="width:100%;"
                        :options="chartOptions"
                        :series="imageGraph.series" />
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>


<script>
import VueApexCharts from 'vue-apexcharts'
import { rest } from '../_helpers'

const moment = require('moment-timezone')


export default {
    props: {
        id: Number,
        period: {
            default() {
                return 15
            },
            type: Number,
        },
        toDate: {
            default() {
                return null
            },
            type: Date,
        },
    },

    data() {
        return {
            endDate: null,
            barOptions: {
                chart: {
                    type: 'area',
                    height: 350,
                    id: 'weather',
                    // group: 'weather',
                    toolbar: {
                        show: true,
                        offsetX: -20,
                        tools: {
                            download: true,
                            selection: false,
                            zoom: true,
                            zoomin: true,
                            zoomout: true,
                            pan: false,
                            reset: true,
                            customIcons: [],
                        },
                    },
                },
                noData: {
                    text: this.$t('no_data'),
                    align: 'center',
                    verticalAlign: 'middle',
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                        color: undefined,
                        fontSize: '14px',
                        fontFamily: undefined,
                    },
                },
                fill: {
                    opacity: 1,
                },
                legend: {
                    // chorizontalAlign: 'left',
                    position: 'right',
                    offsetX: 0,
                    offsetY: 50,
                },
                series: [],
                xaxis: {
                    categories: [],
                    labels: {
                        rotate: 0, // no need to rotate since hiding labels gives plenty of room
                        hideOverlappingLabels: true, // all labels must be rendered
                        formatter: (value, timestamp, opts) => value,
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                tooltip: {
                    y: {
                        formatter(val, {
                            series,
                            seriesIndex,
                            dataPointIndex,
                            w,
                        }) {
                            switch (seriesIndex) {
                            case 0:
                                return `${val} °C`
                            case 1:
                                return `${val} km/h`
                            case 2:
                                return `${val} %`
                            case 3:
                                return `${val} %`
                            case 4:
                                return `${val} km`
                            case 5:
                                return `${val} UVI`
                            default:
                                return `${val}`
                            }
                        },
                    },
                },
            },
            imageGraph: {},
        }
    },

    components: {
        VueApexCharts,
    },

    computed: {
        chartOptions() {
            let options = ({ ...this.barOptions })
            if (this.imageGraph.categories) {
                options = Object.assign(
                    options,
                    {
                        xaxis: { categories: this.imageGraph.categories },
                        // series: this.imageGraph.series # if responsive then put series into this object
                    },
                )
            }
            return options
        },

        lastImageDate() {
            return this.imageGraph.last ? moment(this.imageGraph.last).format('YYYY-MM-DD HH:mm:ss') : 'Unknown'
        },
    },

    methods: {
        getData() {
            const payload = {
                period: this.period,
            }
            if (this.endDate) {
                payload.to = moment(this.endDate).format('YYYY-MM-DD HH:mm:ssZ')
            }

            rest.getSeries('weather', this.id, payload)
                .then(result => {
                    this.imageGraph = result
                })
        },
    },

    created() {
        this.endDate = this.toDate
        this.getData()
    },

    watch: {
        toDate(val) {
            this.endDate = val
            this.getData()
        },
    },
}
</script>
